body,
html {
  margin: 0 !important;
  /*
  Remove below if you want to be able to scroll on the site (aka if it's not emulating a mobile app)
  More info here: https://stackoverflow.com/questions/7768269/ipad-safari-disable-scrolling-and-bounce-effect
  */

  width: 100%;
  /* height: 100% !important; */

  /* background-color: #fff !important; */
  overflow-x: hidden;
}

* {
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.js-focus-visible :focus:not([data-focus-visible-added]) {
  outline: none;
  box-shadow: none;
}

.web3modal-modal-lightbox {
  z-index: 9999 !important;
}

.hover-row:hover {
  background-color: #282727;
}

@-webkit-keyframes GradientBackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes GradientBackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes GradientBackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 
This fixes these weird white borders around loading base64 images.

More info here: https://stackoverflow.com/questions/13151340/how-to-remove-borders-around-broken-images-in-webkit/33387257
*/
img:not([src]) {
  display: none;
}

.blinking {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.bn-notify-custom .bn-notify-dark-mode {
  background-color: #121212 !important;
  border: 1px solid #272727;
}

.bn-notify-custom .bn-notify-notification-info {
  background-color: #121212 !important;
}

.black-bg-option {
  background-color: #121212 !important;
}

.no-underline:focus,
.no-underline:hover,
.no-underline:visited,
.no-underline:link,
.no-underline:active {
  text-decoration: none !important;
}

.hide-bottom-tooltip .apexcharts-xaxistooltip-bottom {
  display: none !important;
}

.black-switch > .chakra-switch__track[data-checked] {
  background-color: #282727 !important;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.confirm-button-disable-font-size-scale {
  transition: font-size 0s !important;
  transition: transform 250ms !important;
}

/* React Responsive Carousel */

.carousel-root {
  width: 100%;
  padding: 20px;
  /* background-color: pink; */
}

.carousel-slider {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.slider-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  background-color: green;
}

/* Chakra UI sm breakpoint */
@media only screen and (min-width: 30em) {
  .carousel-root {
    /* background-color: pink; */
  }
}

/* Chakra UI md breakpoint */
@media only screen and (min-width: 48em) {
  .carousel-root {
    /* background-color: purple; */
  }
}

/* Chakra UI lg breakpoint */
@media only screen and (min-width: 62em) {
  .carousel-root {
    /* background-color: orange; */
    height: 100%;
    padding: 20px;
  }
}
